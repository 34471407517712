<template>
  <a-card :bordered="false" class="card-area">

    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="奖项名称">
              <a-input v-model="queryParams.prizeName" placeholder="奖项名称" />
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="荣誉类型">
              <a-select v-model="queryParams.honorType">
                <a-select-option value="1">个人荣誉</a-select-option>
                <a-select-option value="2">团体荣誉</a-select-option>
                <a-select-option value="3">外部认可</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="获奖频率">
              <a-select v-model="queryParams.frequency">
                <a-select-option value="m">月度</a-select-option>
                <a-select-option value="q">季度</a-select-option>
                <a-select-option value="y">年度</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="5">
            <a-form-item label="年份">
              <a-select
                show-search
                placeholder="年份"
                v-model="queryParams.year"
                style="width: 100%"
              >
                <a-select-option
                  v-for="t in honorYears"
                  :key="t"
                  :value="t"
                  >{{ t }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
				<a-row>
				  <a-col :span="12"></a-col>
				  <a-col :span="12" :style="{ textAlign: 'right' }">
				    <a-button type="primary" @click="search">查询</a-button>
				    <a-button style="margin-left: 8px" @click="reset">重置</a-button>
				  </a-col>
				</a-row>
      </a-form>
    </div>

    <div class="operator">
      <a-button @click="addHonorList">新增</a-button>
      <a-button @click="batchDelete">删除</a-button>
    </div>
    
    <a-table 
      ref="TableInfo" 
      :scroll="{ x: 900 }" 
      :pagination="pagination" 
      @change="handleTableChange" 
      bordered 
      :loading="loading" 
      :columns="columns" 
      :data-source="dataSource"
      :rowKey="(record) => record.id"
      :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
    >
      <a-tag slot="honor-type" slot-scope="text">{{ text=='1'?"个人荣誉":(text=='2'?"团体荣誉":(text=='3'?"外部认可":"未知")) }}</a-tag>
      <a-tag slot="frequency" slot-scope="text">{{ text=='m'?"月度":(text=='q'?"季度":(text=='y'?"年度":"未知")) }}</a-tag>
      <template slot="operation" slot-scope="text, record">
        <a-icon type="edit" theme="twoTone" twoToneColor="#4a9ff5" @click="editHonorList(record)" title="修改"></a-icon>
      </template>
    </a-table>
    <HonorEdit ref="honorEdit" @close="handleHonorEditClose" @success="handleHonorEditSuccess" :honorEditVisiable="honorEditVisiable" />
    <HonorAdd ref="honorAdd" @close="handleHonorAddClose" @success="handleHonorAddSuccess" :honorAddVisiable="honorAddVisiable" />
  </a-card>
</template>

<script>
import HonorEdit from "./HonorEdit"
import HonorAdd from "./HonorAdd"

const columns = [
  {
    title: "荣誉类型",
    dataIndex: "honorType",
    scopedSlots: { customRender: 'honor-type' },
    width: 120
    // ellipsis: true
  },
  {
    title: "获奖频率",
    dataIndex: "frequency",
    scopedSlots: { customRender: 'frequency' },
    width: 120
    // ellipsis: true
  },
  {
    title: "奖项名称",
    dataIndex: "prizeName",
    width: 200
    // ellipsis: true
  },
  {
    title: "获奖对象",
    dataIndex: "prizeTarget",
    width: 300
    // ellipsis: true
  },
  {
    title: "奖项描述",
    dataIndex: "prizeDesc",
    width: 600
    // ellipsis: true
  },
  // {
  //   title: "封面图片",
  //   dataIndex: "coverImage",
  //   width: 500
  //   // ellipsis: true
  // },
  // {
  //   title: "荣誉图片",
  //   dataIndex: "honorImage",
  //   width: 500
  //   // ellipsis: true
  // },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: 'operation' },
    fixed: "right",
  }
];

export default {
  components: {HonorEdit,HonorAdd},
  data() {
    return {
      columns,
      selectedRowKeys: [],
      dataSource: [],
      honorYears: [],
      queryParams: {
        prizeName: "",
        honorType: "",
        frequency: "",
        year: undefined
      },
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ['10', '20', '30', '40', '100'],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      loading: false,
      honorEditVisiable: false,
      honorAddVisiable: false,
    }
  },
  mounted() {
    this.$get("honor/year").then((r) => {
      this.honorYears = r.data.data;
      this.fetch();
    });
  },
  methods: {
    editHonorList(record) {
      this.honorEditVisiable = true;
      this.$refs.honorEdit.setFormFields(record);
    },
    onSelectChange (selectedRowKeys) {
      // console.log("selectedRowKeys==>", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    handleTableChange (pagination, filters, sorter) {
      this.paginationInfo = pagination
      this.fetch({
        ...this.queryParams
      })
    },
    handleHonorEditClose() {
      this.honorEditVisiable = false;
    },
    handleHonorEditSuccess() {
      this.honorEditVisiable = false;
      this.$message.success("修改荣誉相关成功！");
      this.fetch();
    },
    addHonorList() {
      this.honorAddVisiable = true;
    },
    handleHonorAddClose() {
      this.honorAddVisiable = false;
    },
    handleHonorAddSuccess() {
      this.honorAddVisiable = false;
      this.$message.success("添加荣誉相关成功！");
      this.fetch();
    },
    search () {
      this.selectData({
        ...this.queryParams
      })
    },
    fetch(params = {}) {
      this.loading = true
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize
        params.pageSize = this.paginationInfo.pageSize
        params.pageNum = this.paginationInfo.current
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize
        params.pageNum = this.pagination.defaultCurrent
      }
      this.$get('honor', {
        ...params
      }).then((r) => {
        // console.log("荣誉榜管理==>", r.data);
        const pagination = { ...this.pagination };
        pagination.total = r.data.total;
        let rows = r.data.rows;
        // if(rows) {
        //   for (const key in rows) {
        //     rows[key]['key'] = +rows[key]['id'];
        //   }
        // }
        this.dataSource = rows;
        this.pagination = pagination;
        this.loading = false;
      })
    },
    batchDelete() {
      if(!this.selectedRowKeys.length) {
        this.$message.warning("请选择需要删除的记录");
      } else {
        let that = this;
        that.$confirm({
          title: "确定删除所选中的记录？",
          content: "当您点击确定按钮后，这些记录将会被彻底删除",
          centered: true,
          onOk () {
            let ids = that.selectedRowKeys.join();
            that.$delete(`honor/${ids}`)
              .then(() => {
                that.$message.success("删除成功！");
                that.selectedRowKeys = [];
                that.fetch();
              })
              .catch(e => console.log("荣誉删除失败！==> ", e));
          },
          onCancel () {
            that.selectedRowKeys = [];
          }
        })
      }
    },
		reset() {
		  // 取消选中
		  this.selectedRowKeys = [];
		  // 重置分页
		  this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
		  if (this.paginationInfo) {
		    this.paginationInfo.current = this.pagination.defaultCurrent;
		    this.paginationInfo.pageSize = this.pagination.defaultPageSize;
		  }
		  // 重置列过滤器规则
		  this.filteredInfo = null;
		  // 重置列排序规则
		  this.sortedInfo = null;
		  // 重置查询参数
		  this.queryParams = {
		    prizeName: "",
		    honorType: "",
		    frequency: ""
		  },
		  this.fetch();
		},
    selectData(params = {}) {
      this.loading = true;
      // 如果分页信息为空，则设置为默认值
      this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
      this.$refs.TableInfo.pagination.pageSize = this.pagination.defaultPageSize;
      params.pageSize = this.pagination.defaultPageSize;
      params.pageNum = this.pagination.defaultCurrent;
      this.$get("honor", {
        ...params,
      }).then((r) => {
        let data = r.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.loading = false;
        this.dataSource = data.rows;
        this.pagination = pagination;
      });
    },
  }
}
</script>

<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>